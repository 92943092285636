import { ModeType } from 'interfaces';
import React from 'react';
import { renderSize } from 'utils/helpers/renderSize';
import { SpacingType } from 'utils/helpers/spacingUtils';

import { DividerWrapper } from './styles';

const SVGPaths = (color?: string) => {
  const lightColor = color || '#DADADA';
  const darkColor = color || '#666666';

  return {
    horizontal: {
      light: {
        standard: (
          <svg
            viewBox="0 0 764 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 2H763" stroke={lightColor} strokeLinecap="round" />
          </svg>
        ),
        fadeOut: (
          <svg
            viewBox="0 0 764 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2H763"
              stroke="url(#paint0_linear_horizontal_light)"
              stroke-linecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_horizontal_light"
                x1="1"
                y1="2.5"
                x2="763"
                y2="2.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color={lightColor} stop-opacity="0" />
                <stop offset="0.25" stop-color={lightColor} />
                <stop offset="0.75" stop-color={lightColor} />
                <stop offset="1" stop-color={lightColor} stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        ),
      },
      dark: {
        standard: (
          <svg
            viewBox="0 0 764 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 2H763" stroke={darkColor} strokeLinecap="round" />
          </svg>
        ),
        fadeOut: (
          <svg
            viewBox="0 0 764 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 2H763"
              stroke="url(#paint0_linear_horizontal_dark)"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_horizontal_dark"
                x1="1"
                y1="2.5"
                x2="763"
                y2="2.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={darkColor} stopOpacity="0" />
                <stop offset="0.25" stopColor={darkColor} />
                <stop offset="0.75" stopColor={darkColor} />
                <stop offset="1" stopColor={darkColor} stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        ),
      },
    },
    vertical: {
      light: {
        standard: (
          <svg
            viewBox="0 0 5 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.5 1V59" stroke={lightColor} strokeLinecap="round" />
          </svg>
        ),
        fadeOut: (
          <svg
            viewBox="0 0 5 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 1V59"
              stroke="url(#paint0_linear_vertical_light)"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_vertical_light"
                x1="2.5"
                y1="59"
                x2="2.5"
                y2="1"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={lightColor} stopOpacity="0" />
                <stop offset="0.25" stopColor={lightColor} />
                <stop offset="0.75" stopColor={lightColor} />
                <stop offset="1" stopColor={lightColor} stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        ),
      },
      dark: {
        standard: (
          <svg
            viewBox="0 0 5 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.5 1V59" stroke={darkColor} strokeLinecap="round" />
          </svg>
        ),
        fadeOut: (
          <svg
            viewBox="0 0 5 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 1V59"
              stroke="url(#paint0_linear_vertical_dark)"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_vertical_dark"
                x1="2.5"
                y1="59"
                x2="2.5"
                y2="1"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor={darkColor} stopOpacity="0" />
                <stop offset="0.25" stopColor={darkColor} />
                <stop offset="0.75" stopColor={darkColor} />
                <stop offset="1" stopColor={darkColor} stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        ),
      },
    },
  };
};

interface DividerProps extends SpacingType {
  mode?: ModeType;
  direction?: 'horizontal' | 'vertical';
  isFadeOut?: boolean;
  width?: string | number;
  height?: string | number;
  strokeWidth?: string | number;
  color?: string;
}

export default function Divider({
  mode = 'light',
  direction = 'horizontal',
  isFadeOut = false,
  width,
  height,
  strokeWidth,
  color,
  ...rest
}: DividerProps) {
  const defaultSizes = {
    horizontal: {
      width: '100%',
      height: renderSize(strokeWidth || 4),
    },
    vertical: { width: renderSize(strokeWidth || 5), height: '100%' },
  };

  const defaultWidth = width ?? defaultSizes[direction].width;
  const defaultHeight = height ?? defaultSizes[direction].height;

  const svgElement =
    SVGPaths(color)[direction][mode][isFadeOut ? 'fadeOut' : 'standard'];

  const svg = React.cloneElement(svgElement, {
    width: '100%',
    height: '100%',
    preserveAspectRatio: 'none',
  });

  return (
    <DividerWrapper width={defaultWidth} height={defaultHeight} {...rest}>
      {svg}
    </DividerWrapper>
  );
}
