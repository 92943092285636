import styled from 'styled-components';
import { renderSize } from 'utils/helpers/renderSize';
import { SpacingType, spacingUtils } from 'utils/helpers/spacingUtils';

interface DividerWrapperProps extends SpacingType {
  width?: string | number;
  height?: string | number;
}

export const DividerWrapper = styled.div<DividerWrapperProps>`
  width: ${({ width }) => renderSize(width)};
  height: ${({ height }) => renderSize(height)};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
    preserveaspectratio: none;
  }
  ${spacingUtils}
`;
