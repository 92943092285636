import styled from 'styled-components';
import { shouldNotForwardPropsWithKeys } from 'utils/helpers/shouldNotForwardPropsWithKeys';

import { reusableTypographyStyles } from '../styles';
import { ReusableTypographyStylesProps } from '../types';

export const StyledDisplay = styled.h2.withConfig({
  shouldForwardProp:
    shouldNotForwardPropsWithKeys<ReusableTypographyStylesProps>([
      'lineHeight',
    ]),
})<ReusableTypographyStylesProps>`
  ${reusableTypographyStyles}
`;
